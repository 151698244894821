<template>
    <div>
        <div>
            <span>服务提供：湖南立畅健康科技有限公司</span>
            <span class="ml20">订购电话：13507489072</span>
        </div>
       
        <div>
            <div class="container">
                <a class="no-style" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"><span>湘ICP备2023019325号-1</span></a>
                <img class="image ml20" alt="" src="../assets/image/hui.png" />
                <a class="no-style" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010502001720"  target="_blank"><div class="text">湘公网安备43010502001720号</div>  </a>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
.container {
  display: flex; /* 使用flex布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果需要的话 */
}
.image {
  width: 15px; /* 或者你需要的尺寸 */
  margin-right: 5px; /* 可选，为文字和图片之间提供间隔 */
}
.pt10{
    padding-top: 10px;
}
.ml20{
    margin-left: 20px;
}
.no-style {
  color: inherit;
  text-decoration: none;
}
</style>