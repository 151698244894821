<template>
  <div class="content">
    <a href="https://shop.stonepen.cn" target="_blank"><img class="myimg" alt="" src="./assets/image/ding.jpg" /></a>
    <img class="myimg" alt="" src="./assets/image/second.jpg" />
    <img class="myimg" alt="" src="./assets/image/sandayaodian.jpg" />
    <img class="myimg" alt="" src="./assets/image/liuda.jpg" />
    <img class="myimg" alt="" src="./assets/image/wudabaojian.jpg" />
    <img class="myimg" alt="" src="./assets/image/sidajixu.jpg" />
    <img class="myimg" alt="" src="./assets/image/meiyitian.jpg" />
    <MyFooterA class="myfooter" v-if="isDesktop" />
    <MyFooterB class="myfooter" v-else />
  </div>
</template>

<script>
import MyFooterA from './components/MyFooterA.vue'
import MyFooterB from './components/MyFooterB.vue'

export default {
  name: 'App',
  components: {
   MyFooterA,
   MyFooterB
  },
  data() {
    return {
      isDesktop: window.innerWidth > 768
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth > 768;
    }
  }
}
</script>

<style src="./assets/global.css"></style>
<style>
#app {
  width: 100%; /* 基础宽度为100% */
  height: 100%;
  margin: 0 auto; /* 水平居中 */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  max-width: 1024px; /* 最大宽度限制在1200px */
  height: 100%;
  margin: 0 auto; /* 水平居中 */
  background-color: rgb(255, 255, 255);
}
.myfooter{
  width: 100%; /* 基础宽度为100% */
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
}
.myimg {
  width: 100%; /* 基础宽度为100% */
  display: block;
  padding: 0;
  margin: 0;
}
</style>
